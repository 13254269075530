
export const Client = {

    init: function() {

        this.$item = $('[data-client]');

        this.$overview = this.$item.find('[data-client-tab="overview"]');
        this.$details = this.$item.find('[data-client-tab="details"]');
        this.$contentOverview = this.$item.find('[data-client-content-overview]');
        this.$contentDetails = this.$item.find('[data-client-content-details]');

        if ( !this.$overview.length || !this.$details.length || !this.$contentOverview.length || !this.$contentDetails.length )
            return;

        this.$overview.unbind('click'); //unbind for ajax call
        this.$details.unbind('click'); //unbind for ajax call

        this.$overview.on( 'click', this.changePanel.bind(this) );
        this.$details.on( 'click', this.changePanel.bind(this) );

    },

	changePanel(e) {

        if ( $(e.currentTarget).hasClass( 'is-active' ) )
            return;

        var $type = $(e.currentTarget).data( 'client-tab' );
        var $id = $(e.currentTarget).data( 'client-id' );
        var $contentOverview = $('[data-client-content-overview][data-client-id="' + $id + '"]');
        var $contentDetails = $('[data-client-content-details][data-client-id="' + $id + '"]');
        var $overview = $('[data-client-tab="overview"][data-client-id="' + $id + '"]');
        var $details = $('[data-client-tab="details"][data-client-id="' + $id + '"]');

        if ( $type == 'details' ) {
            $contentOverview.hide();
            $contentDetails.fadeToggle();
        } else {
            $contentDetails.hide();
            $contentOverview.fadeToggle();
        }

        $overview.toggleClass( 'is-active' );
        $details.toggleClass( 'is-active' );

	}
        
};

export default Client;