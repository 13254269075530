
import objectFitImages from 'object-fit-images';
// import LazyLoad from "vanilla-lazyload";

export const Site = {

    init: function() {

        objectFitImages();

        // var lazyLoadInstance = new LazyLoad({
        //     // threshold: -200,
        //     elements_selector: [ '.lazy', '.content_block', '.clientGrid_item', '.newsGrid_item', 'video' ]
        // });

        this.loadLinks( 'email' );
        this.loadLinks( 'phone' );
        this.nonLinks();
        this.externalLinks();

        lightbox.option( {
            'fadeDuration' : 200,
            'resizeDuration' : 300,
            'imageFadeDuration' : 200
        } );
    },

	loadLinks( $type = 'phone' ) {

    	let $links = $('[data-' + $type + '-link]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            let $elem = $(elem);
            let $data = $elem.data( $type + '-link' );
            let $icon = $elem.find( '.o-icon' );
            let $noscript = $elem.next( 'noscript' );
            
            if ( !$icon.length )
                $elem.html( $data );

            if ( $type == 'email' ) {
                $elem.attr( 'href', 'mailto:' + $data );
            } else {
                $elem.attr( 'href', 'tel:' + $data.replace(/\s+/g, '') );
            }

            $elem.removeAttr( 'data-' + $type + '-link' );
            $elem.removeClass( 'js-item' );
            $noscript.remove();

        });

	},
    
    nonLinks() {

        let $links = $('a[href="#"]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            elem.removeAttribute('href');

        } );

    },

    externalLinks() {

        let $links = $('a[href]');

        if ( !$links.length )
            return;

        $links.each( (index, elem) => {

            if ( !elem.host || elem.target || elem.href.includes('#') )
                return;

            if ( elem.href.includes('.pdf') || ( elem.host !== window.location.host ) )
                elem.target = '_blank';

        } );

    }

};

export default Site;