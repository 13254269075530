
export const ClientFilter = {

    init: function() {

        this.$filter = $('[data-client-filter]');
        this.$form = $('[data-client-filter-form]');

        if ( !this.$filter.length || !this.$form.length )
            return;

        this.$form.on( 'change', this.changeFilters.bind(this) );

    },

	changeFilters(e) {

        this.$form.submit();

	}
        
};

export default ClientFilter;