
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const Modal = {

    init: function() {

        this.$modal = $('[data-modal]');
        this.$content = $('[data-modal-content]');
        this.$button = $('[data-modal-button]');
        this.$dismiss = $('[data-modal-dismiss]');

        if ( !this.$modal.length || !this.$content.length || !this.$button.length || !this.$dismiss.length )
            return;

        this.$button.removeAttr( 'href' );
        this.$button.removeAttr( 'target' );

        this.$button.on( 'click', this.toggleModal.bind(this) );
        this.$dismiss.on( 'click', this.toggleModal.bind(this) );

    },

	toggleModal(e) {

        this.active = !this.active;

        this.$type = $(e.currentTarget).data('modal-type');

        $('div[data-modal][data-modal-type="' + this.$type + '"]').fadeToggle().css('display', 'flex');

	}
        
};

export default Modal;