
window.$ = window.jQuery;

import '../styles/app.scss';

import { Site } from './modules/site';
import { Slider } from './modules/slider';
import { MobileMenu } from './modules/mobileMenu';
import { TouchMenu } from './modules/touchMenu';
import { Modal } from './modules/modal';
import { Client } from './modules/client';
// import { NewsFilter } from './modules/newsFilter';
import { ClientFilter } from './modules/clientFilter';
import { Loader } from './modules/loader';

Site.init();
Slider.init();
MobileMenu.init();
TouchMenu.init();
Modal.init();
Client.init();
// NewsFilter.init();
ClientFilter.init();
Loader.init();