
export const TouchMenu = {

    init: function() {
        
        this.$menu = $('[data-touch-menu]');
        this.$parent = this.$menu.find('[data-touch-menu-parent]');

        if ( !this.$menu.length || !this.$parent.length )
            return;

        this.$isTouchDevice = 'ontouchstart' in document.documentElement;
        
        if ( !this.$isTouchDevice )
            return;

        this.$parent.on( 'click', this.touchClick.bind(this) );
        this.$parent.on( 'focusout', this.removeTouchClass.bind(this) );

    },

	touchClick(e) {

        let $elem = $(e.currentTarget);

        if ( $elem.hasClass('is-touched') )
            return;

        $elem.addClass('is-touched');
        e.preventDefault();

	},

    removeTouchClass(e) {

        let $elem = $(e.currentTarget);
        $elem.removeClass('is-touched');

    }

};

export default TouchMenu;