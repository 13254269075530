
export const Slider = {

    init: function() {

        this.$slider = $('[data-slider]');

        if ( !this.$slider.length )
            return;

        this.$slider.slick({
            autoplay : true,
            autoplaySpeed : 5000,
            arrows : false,
            dots : true,
            cssEase : 'ease-out',
            appendDots : '[data-slider-dots]'
        });

    }
        
};

export default Slider;