
import { Client } from '../modules/client';

export const Loader = {

    init: function() {

        this.$pagination = $('[data-loader-pagination]');
        this.$button = $('[data-loader-button]');
        this.$items = $('[data-loader-items]');
        this.$url = $('[data-loader-url]');
        this.$ajaxButton = $('[data-loader-pagination-ajax-button]');
        this.$spinner = $('[data-loader-pagination-spinner]');

        if ( !this.$pagination.length || !this.$button.length || !this.$items.length || !this.$ajaxButton.length || !this.$spinner.length )
            return;

        this.$pagination.hide();
        this.$ajaxButton.show();

        this.$url = this.$url.data('loader-url');

        this.$button.on( 'click', this.loadMore.bind(this) );

    },

	loadMore() {

        this.$spinner.show();

        $.ajax({
            url: this.$url,
            type: 'GET',
            success: (data) => {

                let $items = $(data).find('[data-loader-items]').html();
                this.$items.append( $items );

                let $clients = $('[data-client]');

                if ( $clients.length )
                    Client.init();

                let $nextUrl = $(data).find('[data-loader-url]').data('loader-url');
                if ( $nextUrl ) {
                    this.$url = $nextUrl;
                } else {
                    this.$button.hide();
                }

                this.$spinner.hide();

            },
            error: (e) => {
                console.log('Failed to retrieve new items via AJAX.');
                this.$button.hide();
                this.$spinner.hide();
            } 	        

       });

	}
        
};

export default Loader;