
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export const MobileMenu = {

    init: function() {

        this.$menu = $('[data-mobile-menu]');
        this.$toggle = $('[data-mobile-toggle]');

        if ( !this.$menu.length || !this.$toggle.length )
            return;

        this.active = this.$menu.hasClass('is-active');
        this.$toggle.on( 'click', this.setActive.bind(this) );

    },

	setActive() {

        this.active = !this.active;
        this.$menu.fadeToggle();

        if ( this.active ) {
            disableBodyScroll( this.$menu[0] );
        } else {
            enableBodyScroll( this.$menu[0] );
        }

	}
        
};

export default MobileMenu;